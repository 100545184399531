/*
Using formula solid
$primary: #FFC800;
$home: #FFC800;
$away: #E4DFDF;
$cl: rgba(255,255,255, 0.95);
$base: #000000;
*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin,latin-ext");
.sr-bb {
  font-family: "Roboto", "Noto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  background: #000000;
}

.sr-bb.sr-rtl {
  text-align: right;
}

.sr-bb .sr-bb {
  background: none;
}

.sr-bb .srt-base-1 {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-win {
  background-color: transparent;
  color: #FFC800;
  border-color: #FFC800;
}

.sr-bb .srt-base-1-draw {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.4);
  border-color: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-base-1-lose {
  background-color: transparent;
  color: #E4DFDF;
  border-color: #E4DFDF;
}

.sr-bb .srt-base-1-is-active {
  background-color: rgba(255, 200, 0, 0.12);
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-2 {
  background-color: rgba(23, 23, 23, 0.995);
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-hoverable:hover {
  background-color: rgba(255, 200, 0, 0.12);
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-primary {
  background-color: transparent;
  color: #FFC800;
  border-color: #FFC800;
}

.sr-bb .srt-base-1-home-1 {
  background-color: transparent;
  color: #FFC800;
  border-color: #FFC800;
}

.sr-bb .srt-base-1-away-1 {
  background-color: transparent;
  color: #E4DFDF;
  border-color: #E4DFDF;
}

.sr-bb .srt-base-1-home-2 {
  background-color: transparent;
  color: #FFC800;
  border-color: rgba(255, 222, 101, 0.979);
}

.sr-bb .srt-base-1-away-2 {
  background-color: transparent;
  color: #E4DFDF;
  border-color: rgba(255, 222, 101, 0.979);
}

.sr-bb .srt-base-1-home-3 {
  background-color: transparent;
  color: #FFC800;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-away-3 {
  background-color: transparent;
  color: #E4DFDF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-home-4 {
  background-color: transparent;
  color: #b38c00;
  border-color: #b38c00;
}

.sr-bb .srt-base-1-away-4 {
  background-color: transparent;
  color: #a09c9c;
  border-color: #a09c9c;
}

.sr-bb .srt-base-1-home-5 {
  background-color: transparent;
  color: rgba(255, 218, 84, 0.9825);
  border-color: rgba(255, 218, 84, 0.9825);
}

.sr-bb .srt-base-1-away-5 {
  background-color: transparent;
  color: rgba(237, 233, 233, 0.9825);
  border-color: rgba(237, 233, 233, 0.9825);
}

.sr-bb .srt-base-1-background {
  background-color: black;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2 {
  background-color: black;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2-is-active {
  background-color: #1f1800;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2-is-hoverable:hover {
  background-color: #1f1800;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3 {
  background-color: #1f1800;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-is-active {
  background-color: #2b2200;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-is-hoverable:hover {
  background-color: #2b2200;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-background {
  background-color: black;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-4 {
  background-color: #0d0a00;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-5-is-active {
  background-color: #423400;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-5-is-hoverable:hover {
  background-color: #423400;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-6 {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.3);
}

.sr-bb .srt-primary-1 {
  background-color: #FFC800;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-active {
  background-color: #e6b400;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-hoverable:hover {
  background-color: #e6b400;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-disabled {
  background-color: #cca000;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-2 {
  background-color: rgba(255, 215, 71, 0.985);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-3 {
  background-color: #e6b400;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-4 {
  background-color: #997800;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-5 {
  background-color: #997800;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-5-is-hoverable:hover {
  background-color: #FFC800;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-6 {
  background-color: rgba(255, 222, 101, 0.979);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-7 {
  background-color: #FFC800;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8 {
  background-color: #FFC800;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8-is-active-1 {
  background-color: #e6b400;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8-is-active-2 {
  background-color: #e6b400;
  color: #000000;
  border-color: #000000;
}

.sr-bb .srt-primary-9 {
  background-color: #4d3c00;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-primary-10 {
  background-color: #cca000;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-11 {
  background-color: #e6b400;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-12 {
  background-color: #FFC800;
  color: #000000;
  border-color: #000000;
}

.sr-bb .srt-primary-13 {
  background-color: #0d0a00;
  color: #000000;
  border-color: rgba(255, 200, 0, 0.3);
}

.sr-bb .srt-base-1-primary-1 {
  background-color: transparent;
  color: #FFC800;
  border-color: #FFC800;
}

.sr-bb .srt-base-1-primary-2 {
  background-color: transparent;
  color: rgba(255, 215, 71, 0.985);
  border-color: rgba(255, 215, 71, 0.985);
}

.sr-bb .srt-base-1-primary-3 {
  background-color: transparent;
  color: #e6b400;
  border-color: #e6b400;
}

.sr-bb .srt-base-1-primary-4 {
  background-color: transparent;
  color: #997800;
  border-color: #997800;
}

.sr-bb .srt-base-1-primary-5 {
  background-color: transparent;
  color: #997800;
  border-color: #997800;
}

.sr-bb .srt-base-1-primary-6 {
  background-color: transparent;
  color: rgba(255, 222, 101, 0.979);
  border-color: rgba(255, 222, 101, 0.979);
}

.sr-bb .srt-base-1-primary-7 {
  background-color: transparent;
  color: #FFC800;
  border-color: #FFC800;
}

.sr-bb .srt-base-1-primary-8 {
  background-color: transparent;
  color: #FFC800;
  border-color: #FFC800;
}

.sr-bb .srt-base-1-primary-9 {
  background-color: transparent;
  color: #4d3c00;
  border-color: #4d3c00;
}

.sr-bb .srt-base-1-primary-10 {
  background-color: transparent;
  color: #cca000;
  border-color: #cca000;
}

.sr-bb .srt-base-1-primary-11 {
  background-color: transparent;
  color: #e6b400;
  border-color: #e6b400;
}

.sr-bb .srt-base-1-primary-13 {
  background-color: #000000;
  color: #0d0a00;
  border-color: rgba(255, 200, 0, 0.3);
}

.sr-bb .srt-base-1-neutral-1 {
  background-color: transparent;
  color: #1e1e1e;
  border-color: #1e1e1e;
}

.sr-bb .srt-base-1-neutral-2 {
  background-color: transparent;
  color: #e3e3e3;
  border-color: #e3e3e3;
}

.sr-bb .srt-base-1-neutral-3 {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-neutral-4 {
  background-color: transparent;
  color: #a0a0a0;
  border-color: #a0a0a0;
}

.sr-bb .srt-base-1-neutral-5 {
  background-color: transparent;
  color: #bababa;
  border-color: #bababa;
}

.sr-bb .srt-base-1-neutral-6 {
  background-color: transparent;
  color: #393939;
  border-color: #393939;
}

.sr-bb .srt-base-1-neutral-7 {
  background-color: transparent;
  color: #a0a0a0;
  border-color: #a0a0a0;
}

.sr-bb .srt-base-1-neutral-8 {
  background-color: transparent;
  color: #1e1e1e;
  border-color: #1e1e1e;
}

.sr-bb .srt-base-1-neutral-9 {
  background-color: transparent;
  color: rgba(14, 14, 14, 0.997);
  border-color: rgba(14, 14, 14, 0.997);
}

.sr-bb .srt-base-1-neutral-10 {
  background-color: transparent;
  color: #1c1c1c;
  border-color: #1c1c1c;
}

.sr-bb .srt-base-1-neutral-11 {
  background-color: transparent;
  color: rgba(96, 96, 96, 0.98);
  border-color: rgba(96, 96, 96, 0.98);
}

.sr-bb .srt-base-1-neutral-12 {
  background-color: transparent;
  color: #adadad;
  border-color: #adadad;
}

.sr-bb .srt-base-1-neutral-13 {
  background-color: transparent;
  color: #171717;
  border-color: #171717;
}

.sr-bb .srt-base-1-is-active-primary {
  background-color: rgba(255, 200, 0, 0.12);
  color: #FFC800;
  border-color: #FFC800;
}

.sr-bb .srt-base-1-is-active-home-1 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #FFC800;
  border-color: #FFC800;
}

.sr-bb .srt-base-1-is-active-away-1 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #E4DFDF;
  border-color: #E4DFDF;
}

.sr-bb .srt-base-1-is-active-home-2 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #FFC800;
  border-color: rgba(255, 222, 101, 0.979);
}

.sr-bb .srt-base-1-is-active-away-2 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #E4DFDF;
  border-color: rgba(255, 222, 101, 0.979);
}

.sr-bb .srt-base-1-is-active-home-3 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #FFC800;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-away-3 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #E4DFDF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-home-4 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #b38c00;
  border-color: #b38c00;
}

.sr-bb .srt-base-1-is-active-away-4 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #a09c9c;
  border-color: #a09c9c;
}

.sr-bb .srt-base-1-is-active-home-5 {
  background-color: rgba(255, 200, 0, 0.12);
  color: rgba(255, 218, 84, 0.9825);
  border-color: rgba(255, 218, 84, 0.9825);
}

.sr-bb .srt-base-1-is-active-away-5 {
  background-color: rgba(255, 200, 0, 0.12);
  color: rgba(237, 233, 233, 0.9825);
  border-color: rgba(237, 233, 233, 0.9825);
}

.sr-bb .srt-base-1-is-active-primary-1 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #FFC800;
  border-color: #FFC800;
}

.sr-bb .srt-base-1-is-active-primary-2 {
  background-color: rgba(255, 200, 0, 0.12);
  color: rgba(255, 215, 71, 0.985);
  border-color: rgba(255, 215, 71, 0.985);
}

.sr-bb .srt-base-1-is-active-primary-3 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #e6b400;
  border-color: #e6b400;
}

.sr-bb .srt-base-1-is-active-primary-4 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #997800;
  border-color: #997800;
}

.sr-bb .srt-base-1-is-active-primary-5 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #997800;
  border-color: #997800;
}

.sr-bb .srt-base-1-is-active-primary-6 {
  background-color: rgba(255, 200, 0, 0.12);
  color: rgba(255, 222, 101, 0.979);
  border-color: rgba(255, 222, 101, 0.979);
}

.sr-bb .srt-base-1-is-active-primary-7 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #FFC800;
  border-color: #FFC800;
}

.sr-bb .srt-base-1-is-active-primary-8 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #FFC800;
  border-color: #FFC800;
}

.sr-bb .srt-base-1-is-active-primary-9 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #4d3c00;
  border-color: #4d3c00;
}

.sr-bb .srt-base-1-is-active-primary-10 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #cca000;
  border-color: #cca000;
}

.sr-bb .srt-base-1-is-active-primary-11 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #e6b400;
  border-color: #e6b400;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #1e1e1e;
  border-color: #1e1e1e;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #e3e3e3;
  border-color: #e3e3e3;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
  background-color: rgba(255, 200, 0, 0.12);
  color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #a0a0a0;
  border-color: #a0a0a0;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #bababa;
  border-color: #bababa;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #393939;
  border-color: #393939;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #a0a0a0;
  border-color: #a0a0a0;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #1e1e1e;
  border-color: #1e1e1e;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
  background-color: rgba(255, 200, 0, 0.12);
  color: rgba(14, 14, 14, 0.997);
  border-color: rgba(14, 14, 14, 0.997);
}

.sr-bb .srt-base-1-is-active-neutral-10 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #1c1c1c;
  border-color: #1c1c1c;
}

.sr-bb .srt-base-1-is-active-neutral-11 {
  background-color: rgba(255, 200, 0, 0.12);
  color: rgba(96, 96, 96, 0.98);
  border-color: rgba(96, 96, 96, 0.98);
}

.sr-bb .srt-base-1-is-active-neutral-12 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #adadad;
  border-color: #adadad;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
  background-color: rgba(255, 200, 0, 0.12);
  color: #171717;
  border-color: #171717;
}

.sr-bb .srt-home-1 {
  background-color: #FFC800;
  color: #000000;
  border-color: #FFC800;
}

.sr-bb .srt-away-1 {
  background-color: #E4DFDF;
  color: #000000;
  border-color: #E4DFDF;
}

.sr-bb .srt-home-2 {
  background-color: #FFC800;
  color: #000000;
  border-color: rgba(255, 222, 101, 0.979);
}

.sr-bb .srt-away-2 {
  background-color: #E4DFDF;
  color: #000000;
  border-color: rgba(255, 222, 101, 0.979);
}

.sr-bb .srt-home-3 {
  background-color: #FFC800;
  color: #000000;
  border-color: #000000;
}

.sr-bb .srt-away-3 {
  background-color: #E4DFDF;
  color: #000000;
  border-color: #000000;
}

.sr-bb .srt-home-4 {
  background-color: #b38c00;
  color: #000000;
  border-color: #b38c00;
}

.sr-bb .srt-away-4 {
  background-color: #a09c9c;
  color: #000000;
  border-color: #a09c9c;
}

.sr-bb .srt-home-5 {
  background-color: rgba(255, 218, 84, 0.9825);
  color: #000000;
  border-color: rgba(255, 218, 84, 0.9825);
}

.sr-bb .srt-away-5 {
  background-color: rgba(237, 233, 233, 0.9825);
  color: #000000;
  border-color: rgba(237, 233, 233, 0.9825);
}

.sr-bb .srt-home-6 {
  background-color: rgba(255, 200, 0, 0.2);
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-away-6 {
  background-color: rgba(228, 223, 223, 0.2);
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-home-6-is-hoverable:hover {
  background-color: rgba(255, 200, 0, 0.2);
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-away-6-is-hoverable:hover {
  background-color: rgba(228, 223, 223, 0.2);
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-1 {
  background-color: #1e1e1e;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-2 {
  background-color: #e3e3e3;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-3 {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-4 {
  background-color: #a0a0a0;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-5 {
  background-color: #bababa;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-6 {
  background-color: #393939;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-7 {
  background-color: #a0a0a0;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-8 {
  background-color: #1e1e1e;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-9 {
  background-color: rgba(14, 14, 14, 0.997);
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-10 {
  background-color: #1c1c1c;
  color: rgba(255, 255, 255, 0.82);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-11 {
  background-color: rgba(96, 96, 96, 0.98);
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-12 {
  background-color: #adadad;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-13 {
  background-color: #171717;
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-win {
  background-color: #FFC800;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-draw {
  background-color: rgba(255, 255, 255, 0.4);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-lose {
  background-color: #E4DFDF;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-text-secondary {
  opacity: 0.8;
  -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-text-disabled {
  opacity: 0.6;
}

.sr-bb .srt-text-tertiary {
  opacity: 0.7;
}

.sr-bb .srt-primary-1 .srt-text-secondary, .sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
  opacity: 0.7;
  -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-primary-1 .srt-text-disabled, .sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
  opacity: 0.35;
}

.sr-bb .srt-primary-1 .srt-text-tertiary, .sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
  opacity: 0.54;
}

.sr-bb .srt-icon {
  opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
  opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.sr-bb .srt-elevation-2 {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23), 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-elevation-3 {
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.26), 0px 10px 20px 0px rgba(0, 0, 0, 0.19);
}

.sr-bb .srt-elevation-center-2 {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-inset-top-1 {
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-1 {
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-2 {
  box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-2 {
  box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-3 {
  box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-3 {
  box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-fill-blue-card {
  fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
  stroke: #2579ad;
}

.sr-bb .srt-fill-green-card {
  fill: #2cbd00;
}

.sr-bb .srt-stroke-green-card {
  stroke: #2cbd00;
}

.sr-bb .srt-fill-soccer-yellow-card {
  fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
  stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
  fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
  stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
  stroke: #4fbe30;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-in {
  fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
  stroke: #e43b3b;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-out {
  fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
  stroke: #e43b3b;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-own-goal {
  fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
  fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
  stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
  fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
  stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
  fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
  stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
  fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
  stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
  fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
  stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
  fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
  stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
  fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
  stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
  fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
  stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
  fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
  stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
  fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
  stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
  background-color: #f5a623;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-nfl-timeout-2 {
  background-color: rgba(245, 166, 35, 0.3);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-nfl-penalty {
  background-color: #f4a621;
  color: #000000;
  border-color: #f4a621;
}

.sr-bb .srt-nfl-negative-yards-1 {
  background-color: #ac182e;
  color: #000000;
  border-color: #ac182e;
}

.sr-bb .srt-nfl-negative-yards-2 {
  background-color: #ac182e;
  color: #000000;
  border-color: #ac182e;
}

.sr-bb .srt-nfl-first-line {
  background-color: #4b90de;
  color: #000000;
  border-color: #4b90de;
}

.sr-bb .srt-nfl-ten-line {
  background-color: #f5a623;
  color: #000000;
  border-color: #f5a623;
}

.sr-bb .srt-fill-nfl-penalty {
  fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
  stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
  fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
  stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
  fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
  stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
  fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
  stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
  fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
  stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
  background-color: #009900;
  color: #ffffff;
  border-color: #009900;
}

.sr-bb .srt-mlb-run-2 {
  background-color: #ffffff;
  color: #009900;
  border-color: #009900;
}

.sr-bb .srt-mlb-hit-1 {
  background-color: #f7ab11;
  color: rgba(255, 255, 255, 0.95);
  border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-2 {
  background-color: #ffffff;
  color: #f7ab11;
  border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
  background-color: #00a4ff;
  color: #ffffff;
  border-color: #00a4ff;
}

.sr-bb .srt-mlb-error-1 {
  background-color: #d0021b;
  color: #ffffff;
  border-color: #d0021b;
}

.sr-bb .srt-mlb-error-2 {
  background-color: #ffffff;
  color: #d0021b;
  border-color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
  background-color: #212121;
  color: #ffffff;
  border-color: #ffffff;
}

.sr-bb .srt-fill-mlb-base {
  fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
  fill: #00a4ff;
}

.sr-bb .srt-fill-change-increase {
  fill: #FFC800;
}

.sr-bb .srt-stroke-change-increase {
  stroke: #FFC800;
}

.sr-bb .srt-fill-change-decrease {
  fill: #E4DFDF;
}

.sr-bb .srt-stroke-change-decrease {
  stroke: #E4DFDF;
}

.sr-bb .srt-fill-text {
  fill: rgba(255, 255, 255, 0.95);
}

.sr-bb .srt-fill-text-invert {
  fill: #000000;
}

.sr-bb .srt-fill-text-secondary {
  fill-opacity: 0.8;
}

.sr-bb .srt-fill-text-disabled {
  fill-opacity: 0.6;
}

.sr-bb .srt-fill-base-1 {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
  fill: rgba(255, 200, 0, 0.12);
}

.sr-bb .srt-stroke-base-1-active {
  stroke: rgba(255, 200, 0, 0.12);
}

.sr-bb .srt-fill-base-1-active-2 {
  fill: rgba(23, 23, 23, 0.995);
}

.sr-bb .srt-stroke-base-1-active-2 {
  stroke: rgba(23, 23, 23, 0.995);
}

.sr-bb .srt-fill-base-1-primary {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
  stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
  fill: currentColor;
}

.sr-bb .srt-stroke-base-2 {
  stroke: currentColor;
}

.sr-bb .srt-fill-base-2-active {
  fill: #1f1800;
}

.sr-bb .srt-stroke-base-2-active {
  stroke: #1f1800;
}

.sr-bb .srt-fill-base-2-hover {
  fill: #1f1800;
}

.sr-bb .srt-stroke-base-2-hover {
  stroke: #1f1800;
}

.sr-bb .srt-fill-base-3 {
  fill: #1f1800;
}

.sr-bb .srt-stroke-base-3 {
  stroke: #1f1800;
}

.sr-bb .srt-fill-base-3-active {
  fill: #2b2200;
}

.sr-bb .srt-stroke-base-3-active {
  stroke: #2b2200;
}

.sr-bb .srt-fill-base-3-hover {
  fill: #2b2200;
}

.sr-bb .srt-stroke-base-3-hover {
  stroke: #2b2200;
}

.sr-bb .srt-fill-primary-1 {
  fill: #FFC800;
}

.sr-bb .srt-stroke-primary-1 {
  stroke: #FFC800;
}

.sr-bb .srt-fill-primary-2 {
  fill: rgba(255, 215, 71, 0.985);
}

.sr-bb .srt-stroke-primary-2 {
  stroke: rgba(255, 215, 71, 0.985);
}

.sr-bb .srt-fill-primary-3 {
  fill: #e6b400;
}

.sr-bb .srt-stroke-primary-3 {
  stroke: #e6b400;
}

.sr-bb .srt-fill-primary-4 {
  fill: #997800;
}

.sr-bb .srt-stroke-primary-4 {
  stroke: #997800;
}

.sr-bb .srt-fill-primary-5 {
  fill: #997800;
}

.sr-bb .srt-stroke-primary-5 {
  stroke: #997800;
}

.sr-bb .srt-fill-primary-6 {
  fill: rgba(255, 222, 101, 0.979);
}

.sr-bb .srt-stroke-primary-6 {
  stroke: rgba(255, 222, 101, 0.979);
}

.sr-bb .srt-fill-primary-7 {
  fill: #FFC800;
}

.sr-bb .srt-stroke-primary-7 {
  stroke: #FFC800;
}

.sr-bb .srt-fill-primary-8 {
  fill: #FFC800;
}

.sr-bb .srt-stroke-primary-8 {
  stroke: #FFC800;
}

.sr-bb .srt-fill-primary-8-is-active-1 {
  fill: #e6b400;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
  stroke: #e6b400;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
  fill: #e6b400;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
  stroke: #e6b400;
}

.sr-bb .srt-fill-primary-9 {
  fill: #4d3c00;
}

.sr-bb .srt-stroke-primary-9 {
  stroke: #4d3c00;
}

.sr-bb .srt-fill-primary-10 {
  fill: #cca000;
}

.sr-bb .srt-stroke-primary-10 {
  stroke: #cca000;
}

.sr-bb .srt-fill-primary-11 {
  fill: #e6b400;
}

.sr-bb .srt-stroke-primary-11 {
  stroke: #e6b400;
}

.sr-bb .srt-fill-primary-12 {
  fill: #FFC800;
}

.sr-bb .srt-stroke-primary-12 {
  stroke: #FFC800;
}

.sr-bb .srt-fill-home-1 {
  fill: #FFC800;
}

.sr-bb .srt-stroke-home-1 {
  stroke: #FFC800;
}

.sr-bb .srt-fill-home-2 {
  fill: #FFC800;
}

.sr-bb .srt-stroke-home-2 {
  stroke: #FFC800;
}

.sr-bb .srt-fill-home-3 {
  fill: #FFC800;
}

.sr-bb .srt-stroke-home-3 {
  stroke: #FFC800;
}

.sr-bb .srt-fill-home-4 {
  fill: #b38c00;
}

.sr-bb .srt-stroke-home-4 {
  stroke: #b38c00;
}

.sr-bb .srt-fill-home-5 {
  fill: rgba(255, 218, 84, 0.9825);
}

.sr-bb .srt-stroke-home-5 {
  stroke: rgba(255, 218, 84, 0.9825);
}

.sr-bb .srt-fill-away-1 {
  fill: #E4DFDF;
}

.sr-bb .srt-stroke-away-1 {
  stroke: #E4DFDF;
}

.sr-bb .srt-fill-away-2 {
  fill: #E4DFDF;
}

.sr-bb .srt-stroke-away-2 {
  stroke: #E4DFDF;
}

.sr-bb .srt-fill-away-3 {
  fill: #E4DFDF;
}

.sr-bb .srt-stroke-away-3 {
  stroke: #E4DFDF;
}

.sr-bb .srt-fill-away-4 {
  fill: #a09c9c;
}

.sr-bb .srt-stroke-away-4 {
  stroke: #a09c9c;
}

.sr-bb .srt-fill-away-5 {
  fill: rgba(237, 233, 233, 0.9825);
}

.sr-bb .srt-stroke-away-5 {
  stroke: rgba(237, 233, 233, 0.9825);
}

.sr-bb .srt-fill-neutral-1 {
  fill: #1e1e1e;
}

.sr-bb .srt-stroke-neutral-1 {
  stroke: #1e1e1e;
}

.sr-bb .srt-fill-neutral-2 {
  fill: #e3e3e3;
}

.sr-bb .srt-stroke-neutral-2 {
  stroke: #e3e3e3;
}

.sr-bb .srt-fill-neutral-3 {
  fill: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-stroke-neutral-3 {
  stroke: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-fill-neutral-4 {
  fill: #a0a0a0;
}

.sr-bb .srt-stroke-neutral-4 {
  stroke: #a0a0a0;
}

.sr-bb .srt-fill-neutral-5 {
  fill: #bababa;
}

.sr-bb .srt-stroke-neutral-5 {
  stroke: #bababa;
}

.sr-bb .srt-fill-neutral-6 {
  fill: #393939;
}

.sr-bb .srt-stroke-neutral-6 {
  stroke: #393939;
}

.sr-bb .srt-fill-neutral-7 {
  fill: #a0a0a0;
}

.sr-bb .srt-stroke-neutral-7 {
  stroke: #a0a0a0;
}

.sr-bb .srt-fill-neutral-8 {
  fill: #1e1e1e;
}

.sr-bb .srt-stroke-neutral-8 {
  stroke: #1e1e1e;
}

.sr-bb .srt-fill-neutral-9 {
  fill: rgba(14, 14, 14, 0.997);
}

.sr-bb .srt-stroke-neutral-9 {
  stroke: rgba(14, 14, 14, 0.997);
}

.sr-bb .srt-fill-neutral-10 {
  fill: #1c1c1c;
}

.sr-bb .srt-stroke-neutral-10 {
  stroke: #1c1c1c;
}

.sr-bb .srt-fill-neutral-11 {
  fill: rgba(96, 96, 96, 0.98);
}

.sr-bb .srt-stroke-neutral-11 {
  stroke: rgba(96, 96, 96, 0.98);
}

.sr-bb .srt-fill-neutral-12 {
  fill: #adadad;
}

.sr-bb .srt-stroke-neutral-12 {
  stroke: #adadad;
}

.sr-bb .srt-fill-neutral-13 {
  fill: #171717;
}

.sr-bb .srt-stroke-neutral-13 {
  stroke: #171717;
}

.sr-bb .srt-fill-win {
  fill: #FFC800;
}

.sr-bb .srt-stroke-win {
  stroke: #FFC800;
}

.sr-bb .srt-fill-draw {
  fill: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-stroke-draw {
  stroke: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-fill-lose {
  fill: #E4DFDF;
}

.sr-bb .srt-stroke-lose {
  stroke: #E4DFDF;
}

.sr-bb .srt-stop-base-1 {
  stop-color: transparent;
}

.sr-bb .srt-stop-primary-1 {
  stop-color: #FFC800;
}

.sr-bb .srt-stop-primary-2 {
  stop-color: rgba(255, 215, 71, 0.985);
}

.sr-bb .srt-stop-primary-3 {
  stop-color: #e6b400;
}

.sr-bb .srt-stop-primary-4 {
  stop-color: #997800;
}

.sr-bb .srt-stop-primary-5 {
  stop-color: #997800;
}

.sr-bb .srt-stop-primary-6 {
  stop-color: rgba(255, 222, 101, 0.979);
}

.sr-bb .srt-stop-primary-7 {
  stop-color: #FFC800;
}

.sr-bb .srt-stop-primary-8 {
  stop-color: #FFC800;
}

.sr-bb .srt-stop-primary-9 {
  stop-color: #4d3c00;
}

.sr-bb .srt-stop-primary-10 {
  stop-color: #cca000;
}

.sr-bb .srt-stop-primary-11 {
  stop-color: #e6b400;
}

.sr-bb .srt-stop-primary-12 {
  stop-color: #FFC800;
}

.sr-bb .srt-stop-home-1 {
  stop-color: #FFC800;
}

.sr-bb .srt-stop-away-1 {
  stop-color: #E4DFDF;
}

.sr-bb .srt-fill-neutral-14 {
  fill: #000000;
}

.sr-bb .srt-stroke-neutral-14 {
  stroke: #000000;
}

.sr-bb .srt-logo-powered-by-light {
  display: none;
}

.sr-bb .srt-logo-powered-by-dark {
  display: inline-block;
}



/* new */

.sr-bb .sr-loader__container {
  min-height: 25px;
  position: relative;
}
.sr-bb .sr-lmt-plus__segment.srm-scoreboard.srm-compact {
  height: 33px;
}

.sr-bb .sr-lmt-plus-21-scb--compact.sr-lmt-plus-21-scb__wrapper {
  height: 32px;
  -webkit-box-orient: inherit;
  -webkit-box-direction: inherit;
  flex-direction: inherit;
  padding: 0;
}
